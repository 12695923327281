
import "/src/css/app.css";

// Alpine.js
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

Alpine.plugin(intersect)
Alpine.plugin(collapse)

window.Alpine = Alpine

Alpine.start()

// Lazysizes
import 'lazysizes';